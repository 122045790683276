
import Box from '@mui/material/Box';
import './packers.css';
import { TextField, Button } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { useEffect } from 'react';
const inputWidth = { width: '100%' };
const contactNumber = "918861776786";

function randomImage() {
    var images = ["https://img.freepik.com/free-photo/young-manual-worker-unloading-cardboard-boxes-from-delivery-van_637285-2256.jpg?w=2000&t=st=1672313716~exp=1672314316~hmac=3fec30706e74ba43bdcb3dec105430faeb42710817be49587fbb8acb83cabc18", "https://img.freepik.com/premium-photo/carrying-heavy-sofa-two-young-movers-blue-uniform-working-indoors-room_146671-52051.jpg?w=2000", "https://img.freepik.com/free-photo/happy-manual-worker-cooperating-while-carrying-cardboard-boxes-delivery-van_637285-1258.jpg?w=2000&t=st=1672322131~exp=1672322731~hmac=9e307d9b9e5648562c52197a53dd1a83b0f798f60e5346216ca5aee5549c34da"];
    var size = images.length;
    var x = Math.floor(size * Math.random());
    console.log(x);
    document.querySelector(".contentSpace").style.backgroundImage =
        "url(" + images[x] + ")";
}


function Packers() {

    useEffect(() => {
        randomImage();
    });

    return (
        <>
            <div className='leadForm'>

                <div className='header'>
                    <img src="https://movesify.com/favicon.png" style={{ width: '40px', marginBottom: '40px' }} />
                </div>
                <div className='content'>

                    <ul>
                        <li>
                            <h1>Looking to relocate? </h1>
                            <h3>Best Packers and Movers at Lowest Price.</h3>
                        </li>
                        <li><TextField sx={inputWidth} id="outlined-basic" label="Your Name" variant="outlined" /></li>
                        <li><TextField sx={inputWidth} id="outlined-basic" label="Your Mobile Number" variant="outlined" /></li>
                        <li><TextField sx={inputWidth} id="outlined-basic" label="Moving from" variant="outlined" /></li>
                        <li><TextField sx={inputWidth} id="outlined-basic" label="Moving to" variant="outlined" /></li>
                        <li><Button sx={{ width: '350px', fontWeight: 'bold', backgroundColor: '#ffcb00', height: '50px', boxShadow: 0, color: '#000000', '&:hover': { backgroundColor: "#ffd328", boxShadow: 0 } }} variant="contained" size="large">Get free quote</Button></li>
                        <li>&nbsp;</li>
                        <li className='centerAlign'><p>or Call us directly / Chat us on <a target={'_blank'} href={`https://api.whatsapp.com/send?phone=${contactNumber}&text=Hi,%20I'm%20looking%20for%20packers%20and%20movers%20service%20can%20you%20please%20assist%20me`}>WhatsApp</a></p></li>
                        <li><h1 className='centerAlign'>+91-(886)-177-6786</h1> </li>
                    </ul>

                </div>
                <div className='content-empty'></div>

                <div className='content2'>
                    <Carousel autoFocus={true} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} showIndicators={false} useKeyboardArrows className="presentation-mode">
                        <div className='slides' key="slide1">
                            <div className='container'>
                                <h3>Budget friendly and affordable prices</h3>
                                <p>Moving made quick and easy with cheap prices</p>
                            </div>
                        </div>
                        <div className='slides' key="slide2">
                            <div className='container'>
                                <h3>Genuine packing to ensure safe movement</h3>
                                <p>Shift bike, luggage, furniture and electronics with reliable packaging</p>
                            </div>
                        </div>
                        <div className='slides' key="slide3">
                            <div className='container'>
                                <h3>Get best quote according to your apartment size</h3>
                                <p>Transparent charges depending on the shifitng requirment. No hidden charges!</p>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </>
    );
}

export default Packers;