import './App.css';

import Packers from './packersandmovers';






function App() {



  return (
    <div className="app">


      <div className='application'>
        <div className='contentSpace'>
          <div className='content'>

            <div className='content1'>

            </div>

            <div className='content2'>

            </div>
          </div>
        </div>

        <Packers />
      </div>

      <div className='moreServices' style={{ display: 'none' }}>
        Customer Reviews

        <div className='services'>
          <div key="slide11">
            <h4>Share your Requirement</h4>
            <p>Tell us where and when do you want to move</p>
          </div>
          <div key="slide12">
            <h4>Get Free Instant Quote</h4>
            <p>Get guaranteed lowest priced quote for your shifting instantly</p>
          </div>
          <div key="slide13">
            <h4>Schedule and Confirm</h4>
            <p>Pick a slot and pay a token amount to confirm your move</p>
          </div>
          <div key="slide14">
            <h4>We get you moved!</h4>
            <p>Our partner will arive as per schedule to pack & load your belonging</p>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
